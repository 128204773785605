import { gql } from "@apollo/client"

export const getAllTasks = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllTasks(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        title
        description
        createdAt
        status
        deleted
        deletedAt
        views
        budgetsCount
        seenBudgetsCount
        conversationsCount
        budgetsAvg
        taskViews
        acquisitionMethod
        warrantyMonths
        media {
            id
            uri
        }
        owner {
            id
            firstName
            lastName
            profilePicture
            createdAt
        }
        category {titleLng}
        subcategory {titleLng}
        qualification {stars}
        promotionCodeFlat
    }
}`


export const sendNotificationsMutation = gql`mutation sendNotifications($ids: [Int]!, $title: String!, $subtitle: String!) {
    sendCustomNotification(ids: $ids, title: $title, subtitle: $subtitle) 
}`
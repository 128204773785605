import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

export default function FormSwitch({ name, label, ...props }) {
	const {
		input: { value = false, onChange, onBlur },
	} = useField(name);
	return (
		<FormGroup style={props.style}>
			<FormControlLabel
				name={name}
				onBlur={onBlur}
				onChange={(e, p) => onChange(p)}
				labelPlacement="top"
				control={
					<Switch
						// {...props}
						defaultChecked={value}
						defaultValue={value}
                        checked={value}
						variant="outlined"
						color="primary"
						// onClick={onChange}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
}

import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-final-form";

export default forwardRef(function FormUseForm({ initialValues }, ref) {
	const form = useForm();
	// useImperativeHandle(ref, () => ({
	// 	form,
	// }));
	useEffect(() => {
		form.initialize(initialValues);
	}, [initialValues]);

	return null;
});

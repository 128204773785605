import React, { useEffect } from 'react';
import { Box, Avatar, Card, Typography, Stack, Rating, Chip, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { graphQLQuery } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import { get } from "lodash";
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { useSelector } from 'react-redux';
import { selectCompleteDate } from '../../../redux/layout';
import { severityPillRender } from '../../../components/customDataGrid/utils';
import DeleteQualification from './DeleteQualification';
import { WhatsappActionFactory } from '../../../components/SendWhatsappAction';

export default function List(props) {

  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { data, loading }] = useLazyQuery(graphQLQuery, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });
  const rows = data?.getAllQualifications || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (user) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={get(user, "profilePicture")} sx={{ mr: 2 }}>{getInitials(`${get(user, "firstName")} ${get(user, "lastName")}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{get(user, "firstName")} {get(user, "lastName")}</Typography>
    </Box>
  }

  const qualificationView = row => <>
    {row.stars ? <Rating value={row.stars} readOnly /> : null}
  </>

  const getOwner = (row) => {
    const owner = get(row, "budget.owner", get(row, 'package.applications.0.owner'))
    return owner
  }
  const ownerRenderer = {
    customValue: row => `${getOwner(row)?.firstName} ${getOwner(row)?.lastName}`,
    customRender: row => avatarRenderer(getOwner(row))
  }

  const userIdsMapper = (selectedRows) => selectedRows.map((row) => row.owner?.id).filter((id) => !!id);

  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de calificaciones</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          actions={[
            WhatsappActionFactory(userIdsMapper),
            DeleteQualification
          ]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "budget.owner.id", label: "ID Experto", type: "number", hide: true },
            { id: "budget.owner", label: "Experto", ...ownerRenderer },
            // { id: "category/subcategory", label: "Categoria", customValue: row => tableCategorySbucategoryRender(row.task || row.task), customRender: row => tableCategorySbucategoryRender(row.task) },
            { id: "stars", label: "Estrellas", type: "number", customValue: (row) => get(row, "stars"), customRender: qualificationView },
            { id: "acknowledgments", label: "Reconocimientos", customValue: (row) => get(row, "acknowledgments", []).map(a => a.title?.es).join(" - "), customRender: (row) => <Stack direction="row" spacing={1}>{get(row, "acknowledgments", []).map(a => <Chip size='small' color="primary" label={a.title} />)}</Stack> },
            { id: "comment", label: "Comentario de calificacion" },
            { id: "owner.id", label: "ID Usuario", type: "number", hide: true },
            { id: "owner", label: "Usuario", customValue: row => `${get(row, "owner.firstName")} ${get(row, "owner.lastName")}`, customRender: row => avatarRenderer(row.owner) },
            { id: "package.id", label: "ID Paquete", type: "number", hide: true },
            { id: "task.id", label: "ID Tarea", type: "number", hide: true },
            { id: "task.title", label: "Titulo de la tarea", customValue: (row) => get(row, "task.title", get(row, "package.title")) },
            { id: "task.description", label: "Descripcion de la tarea", customValue: (row) => get(row, "task.description", get(row, "package.description")) },
            { id: "task.promotionCode.code", label: "Codigo", flex: 2, customRender: (row) => severityPillRender(row.task?.promotionCode?.code) },
            { id: "budget.amount", label: "Monto del presupuesto", type: "number" },
            { id: "budget.comment", label: "Comentario del presupuesto" },
            { id: "task.paid", label: "Monto pagado", type: "number", customValue: (row) => get(row, "task.paid", get(row, "package.price")) },
            { id: "acquisitionMethod", label: "Metodo de adquisicion" },
          ]} />
      </Box>
    </Card>
  </>);
};